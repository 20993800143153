import React from 'react';
import DarkIcon from '@material-ui/icons/Brightness2';
import LightIcon from '@material-ui/icons/WbSunny';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppTheme } from '@backstage/core-plugin-api';
import { createTheme, lightTheme, darkTheme } from '@backstage/theme';

import { pageTheme } from './pageTheme';

export const light = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#00CC6A',
      dark: '#009950',
      contrastText: '#FFFFFF',
    },
    navigation: {
      ...lightTheme.palette.navigation,
      indicator: '#00CC6A',
    },
    tabbar: {
      ...lightTheme.palette.tabbar,
      indicator: '#00CC6A',
    },
  },
  defaultPageTheme: 'home',
  pageTheme,
});

export const dark = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#00CC6A',
      dark: '#009950',
      contrastText: '#FFFFFF',
    },
    navigation: {
      ...darkTheme.palette.navigation,
      indicator: '#00CC6A',
    },
    tabbar: {
      ...darkTheme.palette.tabbar,
      indicator: '#00CC6A',
    },
  },
  defaultPageTheme: 'home',
  pageTheme,
});

export const themes: AppTheme[] = [
  {
    id: 'light',
    title: 'Light Theme',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={light}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  },
  {
    id: 'dark',
    title: 'Dark Theme',
    variant: 'dark',
    icon: <DarkIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={dark}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  },
];
