import { genPageTheme, PageTheme, shapes } from '@backstage/theme';

export const colorVariants: Record<string, string[]> = {
  darkGrey: ['#171717', '#383838'],
  denim: ['#115ABB', '#448DEE'],
  veryBlue: ['#082D5E', '#115ABB'],
  rubyRed: ['#98002B', '#8D1134'],
  toastyOrange: ['#BE2200', '#A41D00'],
  redViolett: ['#6D1366', '#B71FAB'],
  eveningSea: ['#00FFF2', '#035355'],
  springGreen: ['#009950'],
  pinkSea: ['#FF3C61', '#c50038'],
};

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({ colors: colorVariants.springGreen, shape: shapes.wave }),
  documentation: genPageTheme({
    colors: colorVariants.pinkSea,
    shape: shapes.wave2,
  }),
  tool: genPageTheme({ colors: colorVariants.redViolett, shape: shapes.round }),
  service: genPageTheme({ colors: colorVariants.denim, shape: shapes.wave }),
  website: genPageTheme({ colors: colorVariants.veryBlue, shape: shapes.wave }),
  library: genPageTheme({ colors: colorVariants.rubyRed, shape: shapes.wave }),
  other: genPageTheme({ colors: colorVariants.darkGrey, shape: shapes.wave }),
  app: genPageTheme({ colors: colorVariants.toastyOrange, shape: shapes.wave }),
  apis: genPageTheme({
    colors: colorVariants.springGreen,
    shape: shapes.wave2,
  }),
};
