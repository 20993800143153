import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#00FA82',
  },
  pathContrast: {
    fill: '#F4F4F4',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 338 429"
    >
      <path
        className={classes.path}
        d="M.013 368.273v-.004c0-7.454 6.133-13.588 13.588-13.588.116 0 .233.001.35.004 7.646 0 13.938 6.292 13.938 13.938 0 7.646-6.292 13.938-13.938 13.938-.082.002-.164.002-.246.002C6.187 382.563 0 376.377 0 368.859c0-.196.004-.391.013-.586Zm0-103.106-.002-.18c0-7.454 6.134-13.588 13.588-13.588.118 0 .235.002.352.005.3-.02.601-.03.903-.03 7.678 0 13.996 6.319 13.996 13.997s-6.318 13.996-13.996 13.996c-.302 0-.603-.01-.903-.029l-.242.002c-7.518 0-13.705-6.186-13.705-13.705 0-.156.003-.312.009-.468Zm103.106 0c.032-7.72 6.393-14.055 14.113-14.055 7.742 0 14.113 6.371 14.113 14.113 0 7.743-6.371 14.113-14.113 14.113l-.477.005c-7.486 0-13.646-6.161-13.646-13.647 0-.176.003-.353.01-.529Zm103.165 0c0-7.711 6.345-14.055 14.055-14.055s14.054 6.344 14.054 14.055c0 7.71-6.344 14.054-14.054 14.054s-14.055-6.344-14.055-14.054ZM103.119 162.235c.476-7.385 6.683-13.206 14.084-13.206 7.4 0 13.608 5.821 14.084 13.206.019.302.029.604.029.907 0 7.742-6.371 14.113-14.113 14.113-7.742 0-14.113-6.371-14.113-14.113 0-.303.01-.605.029-.907Zm-103.106 0-.001-.121c0-7.454 6.134-13.588 13.588-13.588.117 0 .234.001.351.004 7.646 0 13.938 6.292 13.938 13.938 0 7.646-6.292 13.938-13.938 13.938-.081.002-.161.002-.242.002-7.518 0-13.705-6.186-13.705-13.704 0-.157.003-.313.009-.469Zm206.271 0c0-7.71 6.345-14.055 14.055-14.055s14.054 6.345 14.054 14.055-6.344 14.055-14.054 14.055-14.055-6.345-14.055-14.055ZM103.119 59.362c.476-7.386 6.683-13.207 14.084-13.207 7.4 0 13.608 5.821 14.084 13.207.019.302.029.604.029.906 0 7.743-6.371 14.113-14.113 14.113-7.742 0-14.113-6.37-14.113-14.113 0-.302.01-.604.029-.906Zm-103.106 0-.002-.18c0-7.454 6.134-13.588 13.588-13.588.118 0 .235.002.352.005.175-.007.35-.011.525-.011 7.486 0 13.646 6.161 13.646 13.647l.004.481c0 7.582-6.239 13.822-13.821 13.822l-.535-.004C6.219 73.534.006 67.321.006 59.771c0-.136.002-.273.007-.409Zm206.271 0c0-7.71 6.345-14.055 14.055-14.055s14.054 6.345 14.054 14.055-6.344 14.054-14.054 14.054-14.055-6.344-14.055-14.054Z"
      />
      <path
        className={classes.pathContrast}
        d="M103.119 368.273c.476-7.385 6.683-13.206 14.084-13.206 7.4 0 13.608 5.821 14.084 13.206.019.302.029.605.029.907 0 7.742-6.371 14.113-14.113 14.113-7.742 0-14.113-6.371-14.113-14.113 0-.302.01-.605.029-.907Zm206.272 0c.063-7.666 6.388-13.938 14.054-13.938 7.71 0 14.055 6.345 14.055 14.055s-6.345 14.055-14.055 14.055l-.418.004c-7.486 0-13.647-6.16-13.647-13.646 0-.177.004-.353.011-.53Zm-103.107 0c0-7.71 6.345-14.054 14.055-14.054s14.054 6.344 14.054 14.054-6.344 14.055-14.054 14.055-14.055-6.345-14.055-14.055Zm103.107-103.106c.063-7.666 6.388-13.938 14.054-13.938 7.71 0 14.055 6.344 14.055 14.054 0 7.711-6.345 14.055-14.055 14.055l-.418.005c-7.486 0-13.647-6.161-13.647-13.647 0-.176.004-.353.011-.529Zm0-102.932c.063-7.666 6.388-13.938 14.054-13.938 7.71 0 14.055 6.345 14.055 14.055s-6.345 14.054-14.055 14.054l-.418.005c-7.486 0-13.647-6.16-13.647-13.646 0-.177.004-.354.011-.53Zm0-102.873c0-7.71 6.344-14.055 14.054-14.055S337.5 51.652 337.5 59.362s-6.345 14.054-14.055 14.054-14.054-6.344-14.054-14.054Z"
      />
    </svg>
  );
};

export default LogoIcon;
